import React, { useState } from 'react';
import logo from '../../images/nft_items/UM_Picture.png'

const HistoryTab = () => {
  const [activeTab, setActiveTab] = useState('bids');

  const changeTab = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="w-[85%] border pb-4 ml-4 mt-4 text-left">
      <div className="flex">
        <button
          className={`flex-1 p-2 border ${
            activeTab === 'bids' ? 'bg-gray-100' : 'bg-white'
          } rounded-l`}
          onClick={() => changeTab('bids')}
        >
          Bids
        </button>
        <button
          className={`flex-1 p-2 border ${
            activeTab === 'history' ? 'bg-gray-100' : 'bg-white'
          } rounded-r`}
          onClick={() => changeTab('history')}
        >
          History
        </button>
      </div>
      <div className={`mt-4 pl-5 ${activeTab !== 'bids' ? 'hidden' : ''}`}>
        {/* Content for the Bids tab */}
      <div className="flex items-center">
      <img
        src={logo}
        alt="Profile 2"
        className="w-8 h-8 rounded-full mr-2"
      />
      <p>user1 has bid <b>100 ETH</b>.  <i>(10 minutes ago)</i></p> 
    </div>
        
      </div>
      <div className={`mt-4 pl-5 ${activeTab !== 'history' ? 'hidden' : ''}`}>
        {/* Content for the History tab */}
        <div className="flex items-center">
      <img
        src={logo}
        alt="Profile 2"
        className="w-8 h-8 rounded-full mr-2"
      />
      <p>seller1 started the auction.  <i>(36 hours ago)</i></p> 
    </div>
      </div>
    </div>
  );
};

export default HistoryTab;