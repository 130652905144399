import React from 'react';

const ItemDetails = ({collectionName ="Collection", itemNumber = 0}) => {
  return (
    <div className="w-1/2 p-4 text-left">
    <h1 className="text-4xl font-bold mb-2">{'Item ' + itemNumber + ' Name' }</h1>
    <h2 className="text-2xl font-medium mb-2">{collectionName[0].toLocaleUpperCase() + collectionName.slice(1).split("-").join(" ")}</h2>
    <div className="flex items-center mb-1">
      <span className="text-xl font-semibold ">Current bid: </span>
      <span className="ml-2 text-gray-600 text-2xl font-helvetica-neue">$1000</span>
    </div>
    <div className="flex items-center -mb-3">
      <span className="text-xl font-semibold">Auction ends in: </span>
      <span className="ml-2 text-gray-600 text-2xl font-helvetica-neue">DD:HH:MM:SS</span>
    </div>
  </div>
  );
};

export default ItemDetails;
