import React from 'react';
import { useState } from 'react';
import { useWeb3Modal } from '@web3modal/react'
import logo from '../images/navbar/Logo - Black.png'
import menu from '../images/navbar/hamburger-menu.png'
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthWrapper';


function Navbar() {
  const navigate = useNavigate()
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { open } = useWeb3Modal()

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const token = localStorage.getItem('token');
  const user = localStorage.getItem('user');
  const { dispatch } = useAuth();

  const handleLogout = () => {
    dispatch({ type: 'LOGOUT' });
    navigate('/');
  };
    
  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-white shadow">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex-shrink-0">
            {/* Logo */}
            <a href="/" className="flex items-center">
              <img
                src={logo}
                alt="Logo"
                className="h-8 w-auto"
              />
            </a>
          </div>
          <div className="lg:hidden">
        <button
          className="flex items-center px-3 py-2 border rounded text-gray-700 border-gray-700 hover:text-black hover:border-black"
          onClick={toggleMenu}
        >
        <img className="w-6 h-6" src={menu} alt="Menu"></img>
        </button>
      </div>
      <div
        className={`lg:flex flex-grow items-center justify-end ${
          isMenuOpen ? 'block' : 'hidden'
        }`}
      >
        {/* Menu items */}
        <div className="w-full block flex-grow flex items-center w-auto">
            {/* Buttons */}
            <div className="text-sm mr-auto">
              <a href='/collection'>
              <button className="ml-20 block mt-4 pb-3 inline-block text-black text-lg hover:text-gray-700 mr-4 ml-0 font-helvetica-neue tracking-wider">
                Explore the Marketplace
              </button>
              </a>
              <a href='/secondary'>
              <button className="ml-16 block mt-4 pb-3 inline-block text-black text-lg hover:text-gray-700 mr-4 ml-0 font-helvetica-neue tracking-wider">
                Secondary Market
              </button>
              </a>
              </div>
              <div className="text-sm ml-auto">
              <button className="-mr-1 rounded-lg bg-black text-white py-2 px-4 font-helvetica-neue font-bold tracking-widest" onClick={() => open()}>
                CONNECT WALLET
              </button>
              </div>
              {token ? 
              <div className='flex'>
              <Link to={"/profile/"+user}>
              <button className='px-5 font-helvetica-neue font-bold tracking-widest'>
                PROFILE
              </button>
              </Link>
              <button className='px-5 font-helvetica-neue font-bold tracking-widest' onClick={handleLogout}>
                LOG OUT
              </button>
              </div>
              : <Link to='/login'>
              <button className='px-5 font-helvetica-neue font-bold tracking-widest'>
                LOG IN
              </button>
              </Link>
              }
          </div>
      </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;