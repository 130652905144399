import React from 'react';

const ItemDescription = () => {
  return (
  <div className="w-full p-4 pr-32 text-left">
    <h1 className="text-2xl font-semibold mb-2">Description</h1>
    <span className="font-helvetica-neue">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam porta mi ac est mollis, eu eleifend urna euismod. Fusce vulputate sollicitudin diam et sagittis. Nam eu elit auctor, eleifend est non, vestibulum orci. Vestibulum diam purus, porta non vulputate at, viverra nec eros.</span>
  </div>
  );
};

export default ItemDescription;