import React from 'react';
import logo from '../images/footer/Logo - White.png'
import linkedinLogo from '../images/footer/linkedin.svg'
import twitterLogo from '../images/footer/square-twitter.svg'

const Footer = () => {
  return (
    <footer className="bg-black text-white">
      <div className="container mx-auto flex flex-wrap py-8">
        <div className="w-full md:w-1/2">
          <div className="flex items-center">
            <div className="logo mr-4 ml-16 w-48 h-auto">
              <img src={logo} alt="Logo" />
              <ul className="mt-10 text-left font-helvetica-neue tracking-[.15em]">
                <li>
                  <a href="/contact">Contact Us</a>
                </li>
                <li className="mt-2">
                  <a href="/terms">Terms of Use</a>
                </li>
                <li className="mt-2">
                  <a href="/privacy">Privacy</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 text-left font-helvetica-neue tracking-[.15em]">
          <div className="newsletter w-full">
            <h3 className="text-lg font-bold">Our Newsletter</h3>
            <p className="mt-4 text-sm tracking-[.18em]">Join our mailing list to stay in the loop with our newest feature <br />
            releases, NFT drops, and tips and tricks for navigating <b>Culturæ</b>.</p>
            <div className="relative w-4/5 content-center mt-6">
              <input
                type="email"
                placeholder="ex. john.doe@mail.com"
                className="bg-[#1A1A1A] border border-[#373737] rounded h-12 px-2 pr-8 text-[#979495] w-full tracking-[.15em]"
              />
              <button
                type="submit"
                className="absolute h-8 w-5/12 right-2 top-2 font-bold text-sm bg-black text-white px-2 py-1 rounded tracking-[.4em]"
              >
                SUBSCRIBE NOW
              </button>
            </div>
          </div>
          <div className="mt-16">
            <div className="social-media mt-4 flex">
              <div className="bg-black border rounded mr-2">
              <a href="https://www.linkedin.com">
                <img className="bg-white h-10 w-10 p-0" src={linkedinLogo} alt="LinkedIn" />
              </a>
              </div>
              
              <div className="bg-black border rounded"><a href="https://www.twitter.com"><img className="bg-white h-10 w-10 p-0" src={twitterLogo} alt="Twitter" /></a></div>
              
            </div>
          </div>
        </div>
      </div>
      <hr className="border-white border-2px mt-10" />
      <div className="text-left  font-helvetica-neue ml-16 py-6 tracking-widest">
        <p className="text-sm">
          Copyright @{new Date().getFullYear()} - <a href="https://phylidor.com">Phylidor SAS</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
