import photo1 from "../images/nft_items/sample.jpg"
import photo2 from "../images/nft_items/sample2.jpg"
import photo3 from "../images/nft_items/sample3.jpg"
import azuki from "../images/nft_items/azuki.jpg"
import azuki2 from "../images/nft_items/azuki2.jpg"
import azuki3 from "../images/nft_items/azuki3.jpg"
import azuki4 from "../images/nft_items/azuki4.jpg"




export const collection1 = [
    {
        photoSrc: photo1,
        collection: "Collection 1",
        name: "Item 1",
        description: "This is the description of this item"
    },
    {
        photoSrc: photo2,
        name: "Item 2",
        collection: "Collection 1",
        description: "This is the description of this item"
    },
    {
        photoSrc: photo3,
        name: "Item 3",
        collection: "Collection 1",
        description: "This is the description of this item"
    },
];

export const collection2 = [
    {
        photoSrc: azuki,
        collection: "Collection 2",
        name: "Item 1",
        description: "This is the description of this item"
    },
    {
        photoSrc: azuki2,
        name: "Item 2",
        collection: "Collection 2",
        description: "This is the description of this item"
    },
    {
        photoSrc: azuki3,
        name: "Item 3",
        collection: "Collection 2",
        description: "This is the description of this item"
    },
    {
        photoSrc: azuki4,
        name: "Item 4",
        collection: "Collection 2",
        description: "This is the description of this item"
    },
];