import React from "react"
import CommentSection from './CommentSection'
import item from "../../images/nft_items/sample.jpg"
import { collection1,collection2 } from "../../utilities/nft-collection";

const LeftPanel = ({ comments, itemNumber=1, collectionName = "collection-1" }) => {

  const collection = {
    "collection1" : collection1,
    "collection2" : collection2
  }
  console.log(collectionName.split("-").join(""))
  return (
    <div className="w-2/3 p-6">
      <img src={collection[collectionName.split("-").join("")][itemNumber-1].photoSrc} alt="NFT Item" className="w-full h-auto mb-4" />
      <CommentSection/>
      {/* Add Comment Box */}
    </div>
  );
};

export default LeftPanel;