import React, { useState } from 'react';
import logo from '../../images/nft_items/UM_Picture.png'

const CommentSection = () => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');

  const handleCommentChange = (event) => {
    setNewComment(event.target.value);
  };

  const handleCommentSubmit = () => {
    if (newComment.trim() !== '') {
      setComments([...comments, newComment]);
      setNewComment('');
    }
  };

  return (
    <div className="pt-2 text-left">
      <h1 className="text-2xl font-semibold mb-4">Comments</h1>
      <div className="space-y-4 max-h-40 overflow-y-scroll">
        {comments.map((comment, index) => (
          <div key={index} className="flex items-start space-x-2">
            <img
              src={logo}
              alt="Profile"
              className="w-10 h-10 rounded-full"
            />
            <div>
              <div className="bg-gray-200 rounded-lg p-2">
                <p className="font-semibold mb-1">seller1</p>
                <p>{comment}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-4 flex">
        <textarea
          className="flex-grow px-3 py-2 border rounded mr-2"
          rows="2"
          placeholder="Add a comment..."
          value={newComment}
          onChange={handleCommentChange}
        ></textarea>
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded"
          onClick={handleCommentSubmit}
        >
          Post Comment
        </button>
      </div>
    </div>
  );
};

export default CommentSection;
