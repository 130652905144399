import React, { useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { AuthProvider, ProtectedRoute } from "./context/AuthWrapper";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import Homepage from "./components/home";
import Category from "./components/category";
import Login from "./components/login";
import ProfilePage from "./components/account_profile";
import "./App.css";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { arbitrum, mainnet, polygon } from "wagmi/chains";
import NotFound from "./components/not_found";
import NftDetails from "./components/nft_details";
import MarketPlace from "./components/marketplace";
import PublicProfilePage from "./components/public_profile";

const chains = [arbitrum, mainnet, polygon];
const projectId = process.env.REACT_APP_WALLET_CONNECT_ID;

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <WagmiConfig config={wagmiConfig}>
          <Navbar />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/collection" element={<MarketPlace />} />
            <Route path="/secondary" element={<Category />} />
            <Route path="/login" element={<Login />} />
            <Route path="/profile/:username" element={<ProfilePage />} />
            <Route
              exact
              path="/collection/:collectionName/:itemNumber"
              element={<NftDetails />}
            />
            <Route
              path="/:profileType/:username"
              element={<PublicProfilePage />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </WagmiConfig>
        <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
      </AuthProvider>
    </div>
  );
}

export default App;
