import React, { useState } from 'react';
import ItemCard from './nft_item';
import photo from "../images/nft_items/sample.jpg";
import { collection1, collection2 } from '../utilities/nft-collection';

const UnderlineTabComponent = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="ml-20 mt-20 w-[90%]">
      <div className=" pt-10 pb-10 text-4xl text-left font-black font-helvetica-neue tracking-[.1em]">
        Welcome to Secondary Market
      </div>
      <div className="flex max-w-md">
        <button
          className={`flex-1 py-2 px-4 font-bold whitespace-nowrap border ${
            activeTab === 0 ? 'bg-gray-500 text-black' : 'bg-white-200'
          }`}
          onClick={() => handleTabClick(0)}
        >
          All NFTs
        </button>
        <button
          className={`flex-1 py-2 px-4 font-bold whitespace-nowrap border ${
            activeTab === 1 ? 'bg-gray-500 text-black' : 'bg-white-200'
          }`}
          onClick={() => handleTabClick(1)}
        >
          Collections
        </button>
      </div>
      <div className="bg-white p-4 w-full">
        {activeTab === 0 && <div className="flex gap-4 items-center w-full">
        {collection1.slice(0,2).map((item,index) => {
          return <ItemCard key={index}
          index={index}
          photoSrc={item.photoSrc}
          name={item.name}
          collection={item.collection}
          description={item.description}
        />
        })}
        {collection2.slice(2).map((item,index) => {
          return <ItemCard key={index}
          index={index}
          photoSrc={item.photoSrc}
          name={item.name}
          collection={item.collection}
          description={item.description}
        />
        })}
            </div>}
        {activeTab === 1 && <div className="flex gap-4 items-center w-full">
          {collection1.slice(0,2).map((item,index) => {
          return <ItemCard key={index}
          index={index}
          photoSrc={item.photoSrc}
          name={item.name}
          collection={item.collection}
          description={item.description}
        />
        })}
        {collection2.slice(2).map((item,index) => {
          return <ItemCard key={index}
          index={index}
          photoSrc={item.photoSrc}
          name={item.name}
          collection={item.collection}
          description={item.description}
        />
        })}
            </div>}
      </div>
    </div>
  );
};

export default UnderlineTabComponent;