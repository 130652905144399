import React, { useEffect, useState } from "react";
import API from "../context/API";
import { API_URL } from "../constants";
import { Link, useNavigate, useParams } from "react-router-dom";
import ItemCard from "./nft_item";
import photo from "../images/nft_items/sample.jpg";
import profile from "../images/profile/profile-picture.png";
import { collection1, collection2 } from "../utilities/nft-collection";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillTwitterSquare,
  AiOutlineLink,
} from "react-icons/ai";

const PublicProfilePage = () => {
  const { username, profileType } = useParams();
  const [profileData, setProfileData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [profileExist, setProfileExist] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const navigate = useNavigate();
  const AllowedProfileTypes = ["contributor", "artist", "nonprofit"];

  useEffect(() => {
    if (AllowedProfileTypes.includes(profileType)) {
      API.get(`${API_URL}/accounts/getPublicProfile/${profileType}/${username}`)
        .then((response) => {
          setProfileData(response.data);
          setProfileExist(true);
          setIsLoading(false);
        })
        .catch((error) => {
          setProfileExist(false);
          setIsLoading(false);
        });
    } else {
      navigate("/page-not-found");
    }
  }, []);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="w-screen h-max flex justify-center">
      {isLoading ? (
        <div className="w-screen h-screen bg-white"></div>
      ) : !profileExist ? (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
          <div className="text-center">
            <h1 className="text-5xl text-gray-800">404</h1>
            <p className="text-2xl text-gray-600 mb-4">Account not found</p>
            <a href="/" className="text-blue-500 font-semibold hover:underline">
              Go to Home Page
            </a>
          </div>
        </div>
      ) : (
        <div className="bg-gray-100 w-full min-h-screen justify-center gap-6">
          <div className="relative flex w-full items-end justify-center h-[400px] bg-gradient-to-r from-cyan-500 to-blue-500">
            <div className="absolute -bottom-20 rounded-full w-44 h-44 border-8 overflow-clip bg-white">
              <img
                src={profile}
                alt="Profile"
                className="w-full h-full object-cover opacity-20"
              />
            </div>
          </div>
          <div className="flex flex-col items-center w-full h-max">
            <div className="flex w-[1440px] text-4xl h-max gap-4">
              <a
                href="https://fb.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillFacebook />
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillTwitterSquare />
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillInstagram />
              </a>
              <a
                href="https://google.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlineLink />
              </a>
            </div>
            <div className="w-[1440px] text-center h-max mt-12">
              <div className="text-6xl font-bold">{profileData.name}</div>
              <div className="text-2xl mt-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Vivamus arcu felis bibendum ut. Placerat duis ultricies lacus
                sed turpis tincidunt id. Sit amet consectetur adipiscing elit
                pellentesque habitant morbi. Ac ut consequat semper viverra nam.
                Tempus imperdiet nulla malesuada pellentesque elit eget gravida.
                Adipiscing elit ut aliquam purus sit amet. Nisl rhoncus mattis
                rhoncus urna neque viverra. Nec ultrices dui sapien eget.
                Gravida in fermentum et sollicitudin ac orci phasellus egestas
                tellus. Ornare arcu odio ut sem. In hac habitasse platea
                dictumst quisque sagittis. Mattis enim ut tellus elementum
                sagittis vitae et leo duis.
              </div>
            </div>
          </div>
          {/* Tab Container */}
          <div className="w-full h-max flex justify-center mb-16">
            <div className="w-4/5 h-max xl:w-3/5 bg-white p-6 mt-28 rounded-lg shadow">
              <div className="border-b pb-2">
                <nav className="-mb-px flex justify-center">
                  {" "}
                  {/* Added justify-center */}
                  <button
                    className={`mr-1 py-2 px-4 text-gray-600 font-medium ${
                      activeTab === 1 ? "border-b-2 border-blue-600" : ""
                    }`}
                    onClick={() => handleTabClick(1)}
                  >
                    NFTs
                  </button>
                  <button
                    className={`mr-1 py-2 px-4 text-gray-600 font-medium ${
                      activeTab === 2 ? "border-b-2 border-blue-600" : ""
                    }`}
                    onClick={() => handleTabClick(2)}
                  >
                    Collection 1
                  </button>
                  <button
                    className={`mr-1 py-2 px-4 text-gray-600 font-medium ${
                      activeTab === 3 ? "border-b-2 border-blue-600" : ""
                    }`}
                    onClick={() => handleTabClick(3)}
                  >
                    Collection 2
                  </button>
                </nav>
              </div>
              {activeTab === 1 && (
                <div className="flex items-center space-x-4">
                  {collection1.slice(0, 2).map((item, index) => {
                    return (
                      <ItemCard
                        key={index}
                        index={index}
                        photoSrc={item.photoSrc}
                        name={item.name}
                        collection={item.collection}
                        description={item.description}
                      />
                    );
                  })}
                  {collection2.slice(0, 2).map((item, index) => {
                    return (
                      <ItemCard
                        key={index}
                        index={index}
                        photoSrc={item.photoSrc}
                        name={item.name}
                        collection={item.collection}
                        description={item.description}
                      />
                    );
                  })}
                </div>
              )}
              {activeTab === 2 && (
                <div className="flex items-center space-x-4">
                  {collection1.slice(0, 2).map((item, index) => {
                    return (
                      <ItemCard
                        key={index}
                        index={index}
                        photoSrc={item.photoSrc}
                        name={item.name}
                        collection={item.collection}
                        description={item.description}
                      />
                    );
                  })}
                </div>
              )}
              {activeTab === 3 && (
                <div className="flex items-center space-x-4">
                  {collection2.slice(0, 2).map((item, index) => {
                    return (
                      <ItemCard
                        key={index}
                        index={index}
                        photoSrc={item.photoSrc}
                        name={item.name}
                        collection={item.collection}
                        description={item.description}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PublicProfilePage;
