import React from 'react';
import logo from '../../images/nft_items/UM_Picture.png'
import eth from '../../images/nft_items/eth.png'

const OwnershipDetails = () => {
  return (
    <>
  <div className="flex">
  <div className="w-1/2 p-4 text-left">
    <p className="text-gray-500 mb-1">Created by</p>
    <div className="flex items-center">
      <img
        src={logo}
        alt="Profile 1"
        className="w-10 h-10 rounded-full mr-2"
      />
      <h2 className="text-lg font-semibold ml-3">seller1</h2>
    </div>
  </div>
  <div className="w-1/2 p-4 text-left">
    <p className="text-gray-500 mb-1">Collection</p>
    <div className="flex items-center">
      <img
        src={logo}
        alt="Profile 2"
        className="w-10 h-10 rounded-full mr-2"
      />
      <h2 className="text-lg font-semibold ml-3">first</h2>
    </div>
  </div>
</div>
<div className="flex">
  <div className="w-1/2 p-4 text-left">
    <p className="text-gray-500 mb-1">Owned by</p>
    <div className="flex items-center">
      <img
        src={logo}
        alt="Profile 1"
        className="w-10 h-10 rounded-full mr-2"
      />
      <h2 className="text-lg font-semibold ml-3">seller1</h2>
    </div>
  </div>
  <div className="w-1/2 p-4 text-left">
    <p className="text-gray-500 mb-1">Blockchain</p>
    <div className="flex items-center">
      <img
        src={eth}
        alt="Profile 2"
        className="w-10 h-10 rounded-full mr-2"
      />
      <h2 className="text-lg font-semibold ml-3">Ethereum</h2>
    </div>
  </div>
</div>
</>
  );
};

export default OwnershipDetails;
