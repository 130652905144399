import React, { createContext, useContext, useReducer } from 'react';
import { Route, Link } from 'react-router-dom';

const AuthContext = createContext();

const initialState = {
  token: localStorage.getItem('token'),
  user: null,
};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('user', action.payload.user)
      return {
        ...state,
        token: action.payload.token,
      };
    case 'LOGOUT':
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
      };
    default:
      return state;
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { state } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        state.token ? (
          <Component {...props} />
        ) : (
          <Link to="/login" />
        )
      }
    />
  );
};