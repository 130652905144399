import React, { useState } from 'react'
import { useAuth } from '../context/AuthWrapper';
import Api from "../context/API"
import { API_URL } from '../constants';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { dispatch } = useAuth();
  const [error, setError] = useState('');
  const navigate = useNavigate()
  
  const handleLogin = async () => {
    try {
      const response = await Api.post(`${API_URL}/accounts/login`, { username, password });
      
      if (response.data.message === "Log in successful") {
        dispatch({ type: 'LOGIN', payload: { token: response.data.accessToken, user: response.data.user } });
        navigate('/');
      } else{
      
        setError('Invalid credentials');
      }
    } catch (error) {
      if (error.response.data.error === "Log in failed"){
        setError("Log in failed")
       }else {
         setError('An error occurred');
       }
    }
  };

  return (
    <div className="bg-gray-100 h-screen flex justify-center items-center">
        <div className="bg-white p-8 rounded shadow-md w-96">
                <div className="mb-4">
                    <label htmlFor="username" className="block text-sm font-medium text-gray-600 font-helvetica-neue tracking-wider">Username</label>
                    <input type="text" id="username" name="username" className="mt-1 p-2 w-full border rounded-md" value={username} onChange={(e)=> {setUsername(e.target.value)}} />
                </div>
                <div className="mb-6">
                    <label htmlFor="password" className="block text-sm font-medium text-gray-600 font-helvetica-neue tracking-wider">Password</label>
                    <input type="password" id="password" name="password" className="mt-1 p-2 w-full border rounded-md" value={password} onChange={(e)=> {setPassword(e.target.value)}}/>
                </div>
                <button onClick={handleLogin} className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 transition duration-300">Log In</button>
                {error && <p className='text-red-500'>{error}</p>}
        </div>
    </div>
);
}

export default Login;