import React from 'react';

const NotFound = () => {
    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="text-center">
                <h1 className="text-5xl text-gray-800">404</h1>
                <p className="text-2xl text-gray-600 mb-4">Page Not Found</p>
                <a href="/" className="text-blue-500 font-semibold hover:underline">
                    Go to Home Page
                </a>
            </div>
        </div>
    );
};

export default NotFound;