import React from 'react';
import { Link } from 'react-router-dom';

const ItemCard = ({ photoSrc, name, description, collection = "", index = 0}) => {
  const link = '/collection/' + collection.toLocaleLowerCase().split(" ").join("-") + '/' + (index +1);
  console.log(link)
  return (
    <Link to={link}><div className="bg-white rounded-lg shadow-md overflow-hidden mt-5 mb-20 w-60 h-100 ">
      <div className="relative aspect-w-1 aspect-h-1 border">
        <img src={photoSrc} alt={name} className="object-cover object-center hover:scale-105 transition-transform duration-300 h-3/5" />
      </div>
      <div className="p-4">
        <h2 className="text-xl font-bold font-helvetica-neue tracking-[.1em] mb-2">{name}</h2>
        <p className="text-gray-600">{description}</p>
      </div>
    </div>
    </Link>
  );
};

export default ItemCard;