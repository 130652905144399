import React from "react"
import LeftPanel from "./nft_details_page/LeftPanel"
import RightPanel from "./nft_details_page/RightPanel"

const NftDetails = () => {
    const commentsData = [
        { username: 'User1', text: 'Great item!' },
        { username: 'User2', text: 'Love it!' },
      ];

    return (
        <div className="flex justify-center p-8 pt-24">
        <LeftPanel collectionName={window.location.href.split("/").slice(-2)[0]} itemNumber={window.location.href.split("/").slice(-2)[1]} comments={commentsData} />
        <RightPanel collectionName={window.location.href.split("/").slice(-2)[0]} itemNumber={window.location.href.split("/").slice(-2)[1]}/>
        </div>
    )
}

export default NftDetails