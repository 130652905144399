import React from 'react';
import ItemDetails from './ItemDetails';
import OwnershipDetails from './OwnershipDetails';
import ItemDescription from './ItemDescription';
import HistoryTab from './HistoryTab';

const RightPanel = ({collectionName ="Collection", itemNumber = 0}) => {
  return (
    <div className="container mx-auto p-4">
      <ItemDetails collectionName={collectionName} itemNumber={itemNumber} />
      <OwnershipDetails />
      <ItemDescription />
      <HistoryTab />
    </div>
  );
};

export default RightPanel;
