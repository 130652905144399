import React from "react";

const Homepage = () => {
  return (
    <div>
      <div className="bg-black h-screen flex flex-col justify-center items-center text-white">
        <h1 className="text-4xl font-bold  font-helvetica-neue tracking-[.25em]">
          Connecting Institutions with
          <br />
          the Future.
        </h1>
      </div>
      <div className="bg-white h-screen flex flex-col items-center text-white">
        <div className="w-full h-[170px] border"></div>
      </div>
    </div>
  );
};

export default Homepage;
