import React, { useEffect, useState } from 'react';
import API from '../context/API'
import { API_URL } from '../constants';
import { useNavigate, useParams } from 'react-router-dom';
import ItemCard from './nft_item';
import photo from "../images/nft_items/sample.jpg";
import profile from "../images/profile/profile-picture.png"
import { collection1, collection2 } from '../utilities/nft-collection';

const ProfilePage = () => {
    const { username } = useParams()
    const [profileData, setProfileData] = useState({})
    const [activeTab, setActiveTab] = useState(1);
    const navigate = useNavigate()

    useEffect(()=> {
            API.get(`${API_URL}/accounts/profile/${username}`).then(response => {
                setProfileData(response.data);
              })
              .catch(error => {
                navigate('/login')
                console.error('Error fetching profile details:', error);
              });
    }, [])

    const handleTabClick = (index) => {
        setActiveTab(index);
      };

    return (
        <div className="bg-gray-100 min-h-screen flex justify-center gap-6">
            {/* Banner */}
            {/* <img src={profileData?.banner} alt="Profile Banner" className="w-full h-100 object-cover" /> */}

            {/* Profile Content */}
            <div className="w-1/3 h-max p-6 mt-28 bg-white rounded-lg shadow"> {/* Adjusted with mt-10 */}
              <div className='text-4xl font-bold mb-4 text-center'>{profileData.username + " Profile"}</div>
                <div className='flex gap-4'>
                  <div className="flex items-end justify-center border-2 w-52 h-52 p-1"> {/* Added -mt-20 and z-10 */}
                  <img src={profile} alt="Profile" />
                  </div>
                  <div className='grow flex items-center'>
                    <div className='text-left'>
                      <div className='text-2xl font-medium'>{"Name: " + profileData.name}</div>
                      <div className='text-2xl font-medium'>{"Account type: " + profileData.accountType}</div>
                      <div className='text-2xl font-medium'>{"Email: " + profileData.emailAddress}</div>
                    </div>
                  </div>
                </div>
            </div>

            {/* Tab Container */}
            <div className="w-4/5 h-max xl:w-3/5 bg-white p-6 mt-28 rounded-lg shadow">
                <div className="border-b pb-2">
                    <nav className="-mb-px flex justify-center"> {/* Added justify-center */}
                        <button className={`mr-1 py-2 px-4 text-gray-600 font-medium ${activeTab === 1 ? "border-b-2 border-blue-600": "" }`} onClick={() => handleTabClick(1)}>
                            NFTs
                        </button>
                        <button className={`mr-1 py-2 px-4 text-gray-600 font-medium ${activeTab === 2 ? "border-b-2 border-blue-600": "" }`} onClick={() => handleTabClick(2)}>
                            Collection 1
                        </button>
                        <button className={`mr-1 py-2 px-4 text-gray-600 font-medium ${activeTab === 3 ? "border-b-2 border-blue-600": "" }`} onClick={() => handleTabClick(3)}>
                            Collection 2
                        </button>
                    </nav>
                </div>
            {activeTab === 1 && <div className="flex items-center space-x-4">
            {collection1.slice(0,2).map((item,index) => {
              return <ItemCard key={index}
              index={index}
              photoSrc={item.photoSrc}
              name={item.name}
              collection={item.collection}
              description={item.description}
            />
            })}
            {collection2.slice(0,2).map((item,index) => {
              return <ItemCard key={index}
              index={index}
              photoSrc={item.photoSrc}
              name={item.name}
              collection={item.collection}
              description={item.description}
            />
            })}
            </div>}
            {activeTab === 2 && <div className="flex items-center space-x-4">
            {collection1.slice(0,2).map((item,index) => {
              return <ItemCard key={index}
              index={index}
              photoSrc={item.photoSrc}
              name={item.name}
              collection={item.collection}
              description={item.description}
            />
            })}
            </div>}
            {activeTab === 3 && <div className="flex items-center space-x-4">
            {collection2.slice(0,2).map((item,index) => {
              return <ItemCard key={index}
              index={index}
              photoSrc={item.photoSrc}
              name={item.name}
              collection={item.collection}
              description={item.description}
            />
            })}
            </div>}
            </div>
        </div>
    );
}

export default ProfilePage;